<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from "@/stores/alertStore";

const loading = ref(false);
const router = useRouter();
const profile = useProfileStore();
const alerts = useAlertStore();

const reminder = async () => {
    loading.value = true;
    let result = await profile.reminder();
    if (result === true) {
        alerts.add('success', 'Success', 'We have sent you a password reminder');
        alerts.add('info', 'Hint', 'Change your password now');
        router.push("/activate");
    } else {
        alerts.add('danger', 'Oops', 'Could not send a password reminder. Are you definitely registered with us?');
        loading.value = false;
    }
}
</script>

<template>
    <div class="reminder container mt-4">
        <h1>Reminder</h1>
        <form @submit.prevent="reminder" class="form-signin">
            <label for="profile.remind.email" class="sr-only">Email address</label>
            <input type="email" id="profile.remind.email" v-model="profile.remind.email" class="form-control"
                placeholder="Email address" required autofocus />
            <button class="btn btn-primary btn-block mt-2" type="submit">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light"
                    style="width: 1.5rem; height: 1.5rem" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Send Reminder</span>
            </button>
        </form>
        <p class="mt-4">
            Remembered your password? Click <router-link to="/login">here</router-link> to login.
        </p>
    </div>
</template>