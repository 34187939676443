<template>
    <div class="dashboard-customers">
        <h3>Customers | <router-link class="btn btn-sm btn-secondary" to="/customer">&plus;</router-link></h3>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="table-responsive">
            <!-- <PaginationView v-if="totalPages && totalPages > 1" @pageNumberUpdate="(n) => pageNo = n" :currentPage="pageNo" :totalPages="totalPages" />
            <p>Page {{ pageNo }}</p> -->
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="customer in system.customers" :key="customer.id">
                        <td><router-link :to="'/customer/'+customer.id">{{ customer.id }}</router-link></td>
                        <td>{{ customer.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useSystemStore } from "@/stores/systemStore";
// import PaginationView from '@/components/PaginationView.vue';

const loading = ref(false);
const pageNo = ref(1);
// const totalPages = ref();
const itemsPerPage = 10;
// const pageNumberReceived = (newPageNo) => {
//     console.log("Rcvd",newPageNo);
//     pageNo.value = newPageNo;
// }
const system = useSystemStore();
onMounted(async () => {
    loading.value = true;
    system.fetchCustomers(itemsPerPage, pageNo);
    loading.value = false;
});

</script>