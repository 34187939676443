<script setup>
import { onMounted, ref } from 'vue';
import { useSystemStore } from "@/stores/systemStore";
import { useProfileStore } from "@/stores/profileStore";
import BarChart from '@/components/BarChart'

const system = useSystemStore();
const profileStore = useProfileStore();
const labels = ref(['January', 'February', 'March', 'April']);
const datasets = ref(
    [{ data: [20, 30, 25, 40] }]
)
const loading = ref(false);

onMounted(async () => {
    loading.value = true;
    labels.value = [];
    let chartLabels = [];
    let chartData = [];
    await system.fetchPayouts();
    system.payouts.forEach((payout) => {
        chartLabels.push(payout.date);
        chartData.push(payout.amount);
    })
    labels.value = chartLabels;
    datasets.value = [{ data: chartData }];
    loading.value = false;
});
</script>

<template>
    <div class="payoutsView">
        <h3>Payouts</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>...</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="payout in system.payouts" :key="payout.id">
                    <td>{{ payout.date }}</td>
                    <td>{{ payout.amount }}</td>
                    <td>{{ payout.status_formatted }}</td>
                    <td>
                        <router-link :to="'/payout/' + payout.id">
                            <i class="bi bi-pencil"></i>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <BarChart v-if="profileStore.profile.isSuper || profileStore.profile.isAuthor"
            style="height: 200px; width: 250px;" :chartData="{
                labels,
                datasets
            }" />
    </div>
</template>
