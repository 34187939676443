import { defineStore } from 'pinia'
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from '@/stores/alertStore';

export const usePaymentStore = defineStore('paymentStore', {
    state: () => ({
        pk: 'pk_test_51LQvjzJ5yqAWVWXsaOHL7vGNy1gJ1OwdRGu5o48GP0bFSfCRLF0J2Mc54ggw09cUluIItQgkVKKRmtNyqyjEWIno00WtJBeLGZ',
        currencySymbol: '£',
        reference: null,
        email: null,
        amount: null,
        client_secret: null,
        status: null,
    }),
    actions: {
        async sendPaymentIntent(paymentIntentID) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/paymentintent-send/" + paymentIntentID;
            const alert = useAlertStore();
            try {
                let response = await fetch(url,{
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                if (response.status == 200) {
                    alert.add('success','Sent','Payment Intent ' + paymentIntentID + ' sent');
                } else alert.add('warning','Not Sent','Payment Intent ' + paymentIntentID + ' could not be sent');
            } catch (err) {
                alert.add('danger','Problem',err);
                return err;
            }
        },
        async cancelPaymentIntent(paymentIntentID) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/paymentintent-cancel/" + paymentIntentID;
            const alert = useAlertStore();
            try {
                let response = await fetch(url,{
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                if (response.status == 200) {
                    alert.add('success','Cancelled','Payment Intent ' + paymentIntentID + ' cancelled');
                } else alert.add('warning','Not Cancelled','Payment Intent ' + paymentIntentID + ' could not be cancelled');
            } catch (err) {
                alert.add('danger','Problem',err);
                return err;
            }
        },
        async refundPaymentIntent(paymentIntentID) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/paymentintent-refund/" + paymentIntentID;
            const alert = useAlertStore();
            try {
                let response = await fetch(url,{
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                if (response.status == 200) {
                    alert.add('success','Refunded','Payment Intent ' + paymentIntentID + ' refunded');
                } else alert.add('warning','Not Refunded','Payment Intent ' + paymentIntentID + ' could not be refunded');
            } catch (err) {
                alert.add('danger','Problem',err);
                return err;
            }
        },
        resolveStatus(payment) {
            if (payment.status == "requires_action" || payment.status == "requires_payment_method") {
                return "Pending";
            } else if (payment.status == "requires_capture") {
                return "Pre-Auth";
            } else if (payment.status == "canceled") {
                return "Cancelled";
            } else if (payment.status == "succeeded") {
                for (let c in payment.charges) {
                    if (payment.charges[c].amount_refunded) return "Refunded";
                }
                return "Paid";
            }
            return "TBC";
        }
    },
})