<script setup>
import { onMounted, ref } from 'vue';
// import { useProfileStore } from "@/stores/profileStore";
import { useSystemStore } from "@/stores/systemStore";
import { useAlertStore } from "@/stores/alertStore";
// const profileStore = useProfileStore();
const system = useSystemStore();
const alertStore = useAlertStore();

const loading = ref(false);

const amendPricing = async () => {
    if (confirm("Confirm this new pricing to be applied?") === true) {
        let result = await system.saveNewPrice();
        (result === true) ? alertStore.add('success', 'Success', 'Pricing Amended') : alertStore.add('danger', 'Could Not Save', result);
    } else alertStore.add('warning', 'Cancelled', 'Pricing Cancelled');
}
onMounted(async () => {
    loading.value = true;
    await system.fetchCurrentPrice();
    loading.value = false;
});
</script>

<template>
    <div class="payoutView">
        <h3>GRO B2C Pricing</h3>
        <div class="pricing">

            <div class="pricing-label">Cert Stnd GRO &pound;</div>
            <input type="text" v-model="system.currentPricing.cert_stnd_gro"
                id="currentPricing.cert_stnd_gro"
                class="form-control form-control-sm" />

            <div class="pricing-label">Cert Stnd CDN &pound;</div>
            <input type="text" v-model="system.currentPricing.cert_stnd_cdn"
                id="currentPricing.cert_stnd_cdn"
                class="form-control form-control-sm" />

            <div class="pricing-label">Cert Priority GRO &pound;</div>
            <input type="text" v-model="system.currentPricing.cert_priority_gro"
                id="currentPricing.cert_priority_gro"
                class="form-control form-control-sm" />

            <div class="pricing-label">Cert Priority CDN &pound;</div>
            <input type="text" v-model="system.currentPricing.cert_priority_cdn"
                id="currentPricing.cert_priority_cdn"
                class="form-control form-control-sm" />

            <div class="pricing-label">Apostille Stnd FCDO &pound;</div>
            <input type="text" v-model="system.currentPricing.apostille_stnd_fcdo"
                id="currentPricing.apostille_stnd_fcdo"
                class="form-control form-control-sm" />

            <div class="pricing-label">Apostille Stnd CDN &pound;</div>
            <input type="text" v-model="system.currentPricing.combined_apostille_stnd"
                id="currentPricing.combined_apostille_stnd"
                class="form-control form-control-sm" />

            <div class="pricing-label">Apostille Prem. FCDO &pound;</div>
            <input type="text" v-model="system.currentPricing.apostille_premium_fcdo"
                id="currentPricing.apostille_premium_fcdo"
                class="form-control form-control-sm" />

            <div class="pricing-label">Apostille Prem. CDN &pound;</div>
            <input type="text" v-model="system.currentPricing.combined_apostille_prem"
                id="currentPricing.apostille_premium_cdn"
                class="form-control form-control-sm" />

            <div class="pricing-label">RMSD &pound;</div>
            <input type="text" v-model="system.currentPricing.rmsd"
                id="currentPricing.rmsd"
                class="form-control form-control-sm" />

            <div class="pricing-label">DHL &pound;</div>
            <input type="text" v-model="system.currentPricing.dhl"
                id="currentPricing.dhl"
                class="form-control form-control-sm" />

            <button class="btn btn-sm btn-primary" @click.prevent="amendPricing">Update</button>
        </div>
    </div>
</template>


<style scoped>
.pricing {
    display: grid;
    gap: .25em;
    align-items: center;
    grid-template-columns: 1fr;
}

.pricing button {
    margin-top: 1em;
    justify-self: flex-start;
}

@media (min-width: 815px) {
    .pricing {
        grid-template-columns: 1fr 2fr;
    }
}

@media (min-width: 1400px) {
    .pricing {
        grid-template-columns: 1fr 2fr 1fr 2fr;
    }
}
</style>