<template>
    <div class="login container">
        <h1 class="mt-4">Login</h1>
        <form @submit.prevent="authenticate" class="form-signin">
            <label for="profile.auth.email" class="sr-only">Email address</label>
            <input type="email" id="profile.auth.email" v-model="profile.auth.email" class="form-control"
                placeholder="Email address" required autofocus />
            <label for="profile.auth.password" class="sr-only">Password</label>
            <input type="password" id="profile.auth.password" v-model="profile.auth.password" class="form-control"
                placeholder="Password" required />
            <button id="loginBtn" class="btn btn-primary btn-block mt-2" type="submit" :disabled="loading">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light"
                    style="width: 1.5rem; height: 1.5rem" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Sign in</span>
            </button>
        </form>
        <p class="mt-4">
            Forgotten password? Click <router-link to="/reminder">here</router-link> to get a
            reminder.
        </p>
        <!-- <p>
            Not yet a User? Click <router-link to="/register">here</router-link> to get
            registered.
        </p> -->
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from "@/stores/alertStore";

const loading = ref(false);
const router = useRouter();
const profile = useProfileStore();
const alerts = useAlertStore();

const authenticate = async () => {
    loading.value = true;
    await profile.login();
    if (profile.jwt) {
        alerts.add('success', 'Success', 'You are logged in');
        router.push("/dashboard");
    } else {
        alerts.add('danger', 'Oops', 'Login failed.');
        loading.value = false;
    }
}
</script>