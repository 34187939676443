<template>
    <div class="alerts" style="opacity: .9;">
        <div v-for="(alert, index) in alerts.messages" :key="index" class="alert text-right"
            :class="'alert-' + alert.type" role="alert">
            <p class="p-0"><b>{{ alert.title }}</b>. {{ alert.text }}</p>
        </div>
    </div>
</template>
<style scoped>
.alerts {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}
.alert {
    margin-bottom: 0;
}
.text-right {
    text-align: right;
}
</style>
<script setup>
import { useAlertStore } from "@/stores/alertStore";
const alerts = useAlertStore();
</script>