import { defineStore } from 'pinia'
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from '@/stores/alertStore';

export const useSystemStore = defineStore('systemStore', {
    state: () => ({
        customers: [],
        payments: [],
        users: [],
        orders: [],
        pricing: [],
        reference: [],
        payouts: [],
        balanceTransactions: [],
        currentPricing: {}
    }),
    actions: {
        shallowDifferent(object1, object2, keys) {
            for (let key of keys) {
                if (object1[key] !== object2[key]) return true;
            }
            return false;
        },
        async login() {
            const url = this.rootApi + + "/guest/login";
            let status;
            try {
                let response = await fetch(url, {
                    method: 'post',
                    body: JSON.stringify(this.auth)
                })
                status = response.status;
                if (status == 200) {
                    this.loggingAdd("Login Success");
                    this.jwt = await response.json();
                    this.loggingAdd(this.jwt);
                    this.storeJWT(this.jwt);
                    this.fetchProfile();
                }
            } catch (err) { console.log(err); }
        },
        async fetchCustomers() {
            const profile = useProfileStore();
            let url = profile.rootApi + "/admin/customers";
            this.customers = [];
            try {
                let response = await fetch(url, {
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                if (response.status == 200) this.customers = await response.json();
                return true;
            } catch (err) {
                console.error(err);
                return err;
            }
        },
        async saveCustomer(customer) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/customer";
            let method = (customer.id) ? 'patch' : 'post';
            try {
                let response = await fetch(url, {
                    method,
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                    body: JSON.stringify({ customer })
                });
                if (response.status == 200) this.customers = await response.json();
                return true;
            } catch (err) {
                return err;
            }
        },
        async saveUser(user) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/user";
            let method = (user.id) ? 'patch' : 'post';
            try {
                let response = await fetch(url, {
                    method,
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                    body: JSON.stringify({ user })
                });
                if (response.status == 200) this.users = await response.json();
                return true;
            } catch (err) {
                console.error(err);
                return err;
            }
        },
        // eslint-disable-next-line
        async fetchPayments(filter) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/payments";
            try {
                let response = await fetch(url, {
                    method: 'post',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                    body: JSON.stringify({ filter })
                });
                if (response.status == 200) {
                    this.payments = await response.json();
                    // if (data.data.length == 0) {
                    //     console.log("No data");
                    //     this.payments = [];
                    //     return true;
                    // }
                    // if (data.length != this.payments.length) {
                    //     this.payments = data;
                    //     return true;
                    // }
                    // for (let d in data) {
                    //     if (this.shallowDifferent(data[d].data,this.payments.data[d],['id','status','amount'])===true) this.payments = data;
                    // }
                    return true;
                } else console.error(await response.json());
                this.payments = [];
            } catch (err) {
                this.payments = [];
                console.error(err);
                return err;
            }
        },
        async fetchUsers() {
            this.users = [];
            const profile = useProfileStore();
            const url = profile.rootApi + "/super/users";
            this.users = [];
            const alert = useAlertStore();
            try {
                let response = await fetch(url, {
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                let data = await response.json();
                if (response.status == 200) {
                    this.users = data;
                } else {
                    alert.add('warning', 'Server Declined', data);
                }
            } catch (err) {
                console.error(err);
                return err;
            }
        },
        async newUser(email) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/users";
            // console.log("Adding ",email.value);
            try {
                let response = await fetch(url, {
                    method: 'post',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                    body: JSON.stringify({ email: email })
                });
                if (response.status == 200) this.users = await response.json();
                return true;
            } catch (err) {
                return err;
            }
        },
        async fetchReference() {
            const profile = useProfileStore();
            const url = profile.rootApi + "/guest/reference";
            try {
                let response = await fetch(url, {
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                if (response.status == 200) this.reference = await response.json();
                return true;
            } catch (err) {
                return err;
            }
        },
        async savePayment(payment) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/admin/payment";
            let method = (payment.id) ? 'patch' : 'post';
            console.log("Url", url, "Method", method, "JWT", profile.jwt);
            console.log("Email", payment.email, "Ref", payment.reference, "Amount", payment.amount, "Biz Unit", payment.businessUnit);
            try {
                let response = await fetch(url, {
                    method,
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                    body: JSON.stringify(payment)
                });
                let data = await response.json();
                if (response.status == 200) return true;
                console.error(data);
                return data;
            } catch (err) {
                console.error(err);
                return err;
            }
        },
        async fetchPayouts() {
            const profile = useProfileStore();
            const url = profile.rootApi + "/finance/payouts";
            const alert = useAlertStore();
            try {
                let response = await fetch(url, {
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                let data = await response.json();
                if (response.status == 200) {
                    this.payouts = data;
                } else {
                    this.payouts = [];
                    alert.add('warning', 'Server Declined', data);
                }
            } catch (err) {
                this.payouts = [];
                alert.add('danger', 'Server Issue', err);
                console.error(err);
                return err;
            }
        },
        async fetchBalanceTransactions(stripePayoutID) {
            const profile = useProfileStore();
            const url = profile.rootApi + "/finance/balance-transactions/" + stripePayoutID;
            const alert = useAlertStore();
            try {
                let response = await fetch(url, {
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                let data = await response.json();
                if (response.status == 200) {
                    this.balanceTransactions = data;
                } else {
                    this.balanceTransactions = [];
                    alert.add('warning', 'Server Declined', data);
                }
            } catch (err) {
                this.balanceTransactions = [];
                alert.add('danger', 'Server Issue', err);
                console.error(err);
                return err;
            }
        },
        async fetchCurrentPrice() {
            const profile = useProfileStore();
            const url = profile.rootApi + "/finance/current-pricing";
            const alert = useAlertStore();
            try {
                let response = await fetch(url, {
                    method: 'get',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                });
                let data = await response.json();
                if (response.status == 200) {
                    this.currentPricing = data;
                } else {
                    this.currentPricing = {};
                    alert.add('warning', 'Server Declined', data);
                }
            } catch (err) {
                this.currentPricing = {};
                alert.add('danger', 'Server Issue', err);
                console.error(err);
                return err;
            }
        },
        async saveNewPrice() {
            const profile = useProfileStore();
            const url = profile.rootApi + "/finance/current-pricing";
            const alert = useAlertStore();
            try {
                let response = await fetch(url, {
                    method: 'post',
                    headers: new Headers({
                        Authorization: "Bearer " + profile.jwt,
                    }),
                    body: JSON.stringify(this.currentPricing)
                });
                let data = await response.json();
                if (response.status == 200) {
                    this.currentPricing = data;
                    return true;
                } else {
                    this.currentPricing = {};
                    alert.add('warning', 'Server Declined', data);
                }
            } catch (err) {
                this.currentPricing = {};
                alert.add('danger', 'Server Issue', err);
                console.error(err);
                return err;
            }
        },
        fetchPayment(paymentID) {
            for (let p in this.payments) {
                if (this.payments[p].id == paymentID) return this.payments[p];
            }
            return paymentID;
        },
        fetchCustomer(customerID) {
            // if (this.customers.length == 0) await this.fetchCustomers();
            for (let c in this.customers) {
                // console.log()
                if (this.customers[c].id == customerID) {
                    // console.log("Found",this.customers[c].email);
                    return this.customers[c];
                }
            }
            // console.log("Nope");
            return customerID;
        },
        fetchUser(userID) {
            for (let u in this.users) {
                if (this.users[u].id == userID) return this.users[u];
            }
        },
        getBusinessUnit(paymentIntent) {
            return (paymentIntent.metadata && paymentIntent.metadata.businessUnit) ? paymentIntent.metadata.businessUnit : "-";
            // console.log("Resolving BU for",paymentIntent.id);
            // for (let m in paymentIntent.metadata) {
            //     console.log("Checking",m);
            //     if (m == "businessUnit") {
            //         console.log("Found",paymentIntent.metadata.businessUnit);
            //         return paymentIntent.metadata[m].businessUnit;
            //     } 
            // }
            // return "TBC";
        },
        getPaymentReference(paymentIntent) {
            return (paymentIntent.description && paymentIntent.metadata.reference) ? paymentIntent.metadata.reference : "-";
        },
        getPayout(stripePayoutID) {
            for (let p in this.payouts) {
                if (this.payouts[p].id == stripePayoutID) return this.payouts[p];
            }
        },
        // eslint-disable-next-line
        resolvePaymentOwner(payment) {
            if (!payment.metadata || !payment.metadata.ownerID) return null;
            let user = this.fetchUser(payment.metadata.ownerID);
            let returnVal;
            if (user) {
                returnVal = (user.firstname && user.lastname) ? user.firstname + ' ' + user.lastname.charAt(0) : user.email;
            }
            return returnVal;
        }
    },
    persist: true,
})