<template>
    <div class="payoutView">
        <h3>Payout</h3>
        <div class="payoutItem">
            {{ payout.description }}
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Description</th>
                    <th class="text-right">Amount</th>
                    <th class="text-right">Fees</th>
                    <th class="text-right">Net</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="balanceTransaction in system.balanceTransactions" :key="balanceTransaction.id">
                    <td>{{ balanceTransaction.description }}</td>
                    <td class="text-right">{{ balanceTransaction.amount }}</td>
                    <td class="text-right">{{ balanceTransaction.fee }}</td>
                    <td class="text-right">{{ balanceTransaction.net }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style scoped>
    .text-right {
        text-align: right;
    }
</style>
<script setup>
import { onMounted, ref } from 'vue';
import { useSystemStore } from "@/stores/systemStore";
import { useRoute } from 'vue-router';

const route = useRoute();
const system = useSystemStore();

const loading = ref(false);
const payout = ref({});

onMounted(async () => {
    if (route.params.id) {
        loading.value = true;
        payout.value = system.getPayout(route.params.id);
        await system.fetchBalanceTransactions(route.params.id);
        loading.value = false;
    }
});
</script>