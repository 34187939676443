<template>
    <div class="container payment">
        <h3>Payment</h3>
        <form @submit.prevent="savePayment">
            <label for="payment.email">Email</label>
            <input type="email" v-model="payment.email" id="payment.email" class="form-control" placeholder="eg customer.name@example.com" :readonly="payment.id" required>
            <label for="payment.reference">Reference</label>
            <input type="text" v-model="payment.reference" id="payment.reference" class="form-control" placeholder="eg CDN123">
            <label for="payment.amount">Amount</label>
            <input type="text" v-model="payment.amount" id="payment.amount" class="form-control" placeholder="eg 12.34" required>
            <label for="payment.businessUnit">Department</label>
            <select v-model="payment.businessUnit" id="payment.businessUnit" class="form-control" required>
                <option v-for="businessUnit in profile.reference.businessUnits" :key="businessUnit.id" :value="businessUnit.id">{{ businessUnit.name }}</option>
            </select>
            <div class="buttons d-flex justify-content-between">
                <!-- <button class="btn btn-sm btn-success mt-2">Save & Send</button> -->
                <button class="btn btn-sm btn-primary mt-2">Save</button>
            </div>
        </form>
    </div>
</template>
<style scoped>
    input::placeholder {
        font-style: italic;
        font-size: .8em;
    }
</style>
<script setup>
    import { ref, onBeforeMount } from 'vue';
    import { useSystemStore } from '@/stores/systemStore';
    import { useProfileStore } from '@/stores/profileStore';
    import { useAlertStore } from '@/stores/alertStore';
    import { useRoute, useRouter } from 'vue-router';
    const systemStore = useSystemStore();
    const profile = useProfileStore();
    const alerts = useAlertStore();
    const payment = ref({});
    const route = useRoute();
    const router = useRouter();
    const savePayment = async () => {
        if (confirm("Create Payment " + payment.value.amount + " for " + payment.value.email + "?") === true) {
            let result = await systemStore.savePayment(payment.value); 
            if (result===true) {
                router.push('/payments');
            } else {
                alerts.add('danger','Could not create Payment',result);
            }
        }
    }
    onBeforeMount( () => {
        if (route.params.id) {
            payment.value = systemStore.fetchPayment(route.params.id);
            let customer = systemStore.fetchCustomer(payment.value.customer);
            payment.value.email = customer.email;
        }
    })
</script>