<template>
    <div class="loggingView">
        <h3>Log</h3>
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="logLine in logging.logLines" :key="logLine.id">
                    <td>{{ logLine.created_formatted }}</td>
                    <td>{{ logLine.description }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup>
    import { onMounted, ref } from 'vue';
    import { useLoggingStore } from '@/stores/loggingStore';
    // import { useSystemStore } from "@/stores/systemStore";

    const logging = useLoggingStore();
    // const system = useSystemStore();
    const loading = ref(false);

    onMounted(async () => {
        loading.value = true;
        logging.Fetch();
        loading.value = false;
    });
</script>