import { createRouter, createWebHistory } from 'vue-router'
// import { nextTick } from 'vue'

import HomeView from '../views/Guest/HomeView.vue'
import LoginView from '@/views/Guest/LoginView.vue'
// import RegisterView from '@/views/Guest/RegisterView.vue'
import ReminderView from '@/views/Guest/ReminderView.vue'
import ActivateView from '@/views/Guest/ActivateView.vue'

// import PaymentView from '@/views/Guest/PaymentView.vue'
// import PaidView from '@/views/Guest/PaidView.vue'

import DashboardView from '@/views/User/DashboardView.vue'
// import ProfileView from '@/views/User/ProfileView.vue'
import PasswordView from '@/views/User/PasswordView.vue'

import CustomerView from '@/views/User/CustomerView.vue'
import CustomersView from '@/views/User/Dashboard-Customers.vue'

import UsersView from '@/views/User/Dashboard-Users.vue'
import UserView from '@/views/User/Dashboard-User.vue'

import DashboardPayments from '@/views/User/Dashboard-Payments.vue'
import PaymentView from '@/views/User/PaymentView.vue'
import PayoutsView from '@/views/User/Dashboard-Payouts.vue'
import PricingView from '@/views/User/Dashboard-Pricing.vue'
import PayoutView from '@/views/User/Dashboard-Payout.vue'
import DashboardCountries from '@/views/User/Dashboard-Countries.vue'

import LoggingView from '@/views/User/LoggingView.vue'

import { useProfileStore } from "@/stores/profileStore";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: RegisterView
    // },
    {
        path: '/reminder',
        name: 'reminder',
        component: ReminderView
    },
    {
        path: '/activate/:email?/:code?',
        name: 'activate',
        component: ActivateView
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
    },
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: ProfileView
    // },
    {
        path: '/password',
        name: 'password',
        component: PasswordView
    },
    {
        path: '/customer/:id?',
        name: 'CustomerView',
        component: CustomerView
    },
    {
        path: '/customers',
        name: 'CustomersView',
        component: CustomersView
    },
    {
        path: '/user/:id?',
        name: 'UserView',
        component: UserView
    },
    {
        path: '/users',
        name: 'UsersView',
        component: UsersView
    },
    {
        path: '/countries',
        name: 'DashboardCountries',
        component: DashboardCountries
    },
    {
        path: '/payments',
        name: 'DashboardPayments',
        component: DashboardPayments
    },
    {
        path: '/payment/:id?',
        name: 'PaymentView',
        component: PaymentView
    },
    {
        path: '/payouts',
        name: 'PayoutsView',
        component: PayoutsView
    },
    {
        path: '/pricing',
        name: 'PricingView',
        component: PricingView
    },
    {
        path: '/payout/:id?',
        name: 'PayoutView',
        component: PayoutView
    },
    {
        path: '/logging',
        name: 'LoggingView',
        component: LoggingView
    },
    // {
    //   path: '/paid',
    //   name: 'paid',
    //   component: PaidView
    // },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to) => {
    // await nextTick();
    const sidebarMenu = document.querySelector("#sidebarMenu");
    if (sidebarMenu) sidebarMenu.classList.remove("show");
    const profile = useProfileStore();
    const guestPaths = [
        '/',
        '/register',
        '/about',
        '/activate',
        '/login',
        '/logout',
        '/reminder',
        '/terms-and-conditions'
    ];
    if (profile.jwt && guestPaths.includes(to.path)) return { path: '/dashboard' }
    for (let g in guestPaths) {
        if (guestPaths[g] != "/" && to.path.substring(0, guestPaths[g].length) == guestPaths[g]) return true;
    }
    if (!to || !to.path || profile.jwt) return true;
    return { path: '/login' };
})
export default router