import { defineStore } from "pinia";
// import { useProfileStore } from "./profileStore";

export const useCountryStore = defineStore("countryStore", {
    state: () => ({
        endPoints: {
            all: '/public/countries',
        },
        countries: [],
    }),
    getters: {
        hague() {
            let returnVal = [];
            for (let c in this.countries) {
                if (this.countries[c].isHague == 1) returnVal.push(this.countries[c]);
            }
            return returnVal;
        },
        nonHague() {
            let returnVal = [];
            for (let c in this.countries) {
                if (!this.countries[c].isHague) returnVal.push(this.countries[c]);
            }
            return returnVal;
        },
        countryCallingList() {
            let returnVal = [];
            for (let c in this.countries) {
                if (this.countries[c].cc2 && this.countries[c].callingCode) returnVal.push(this.countries[c]);
            }
            return returnVal;
        }
    },
    actions: {
        countryName(countryID) {
            for (let c in this.countries) {
                if (this.countries[c].id == countryID) return this.countries[c].name;
            }
        },
        async FetchAll() {
            // const profileStore = useProfileStore();
            // const url = profileStore.apiEndpoint + this.endPoints.all;
            const url = "https://api.apostilleexpress.co.uk/public/countries";
            const method = 'GET';
            try {
                let response = await fetch(url, {
                    method
                });
                let data = await response.json();
                if (response.status == 200) {
                    this.countries = data;
                    return true;
                } else return data;
            } catch (err) {
                return err;
            }
        },
    }
});
