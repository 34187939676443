<template>
    <div class="dashboard-users">
        <h3>Users | <router-link class="btn btn-sm btn-outline-dark" to="/user">&plus;</router-link></h3>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in system.users" :key="user.id">
                        <td><router-link :to="'/user/'+user.id">{{ user.id }}</router-link></td>
                        <td>{{ user.firstname }} {{ user.lastname }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.telephone }} {{ user.mobile }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useSystemStore } from "@/stores/systemStore";

const loading = ref(false);
const system = useSystemStore();
onMounted(async () => {
    loading.value = true;
    system.fetchUsers();
    loading.value = false;
});

</script>