<template>
    <div class="passwordView">
        <h3>Change Password</h3>
        <form @submit.prevent="savePassword">
            <label for="profile.changePassword.password1">Enter Password</label>
            <input type="password" id="profile.changePassword.password1" v-model="profile.changePassword.password1"
                class="form-control form-control-sm" />
            <label for="profile.changePassword.password2">Repeat Password</label>
            <input type="password" id="profile.changePassword.password2" v-model="profile.changePassword.password2"
                class="form-control form-control-sm" />

            <button id="loginBtn" class="btn btn-sm btn-primary mt-2" type="submit" :disabled="loading">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light"
                    style="width: 1.5rem; height: 1.5rem" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Save</span>
            </button>
        </form>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from "@/stores/alertStore";
import router from '@/router';

const profile = useProfileStore();

const alerts = useAlertStore();

const loading = ref(false);
const savePassword = async () => {
    loading.value = true;
    let result = await profile.savePassword();
    if (result === true) {
        router.push('/');
        alerts.add('success', 'Super Secure!', 'Password updated');
    } else alerts.add('warning', 'Ooops', result);
    loading.value = false;
}
</script>