<template>
  <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="/">
    <img src="https://images.squarespace-cdn.com/content/v1/5628e080e4b040091d04af60/1445973807663-VTQ56XUD7OR35RZEMC73/CDN+LOGO+433.png?format=1500w" alt="CDN Consular Services" style="height:72px;">
  </a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- <div class="navbar-nav">
    <div class="nav-item text-nowrap">
      <button class="nav-link px-3 sign-out-btn" @click.prevent="logout">Sign out</button>
    </div>
  </div> -->
</header>
</template>
<style scoped>
  .navbar .navbar-toggler {
    top: auto;
  }
  .sign-out-btn {
    background-color: transparent;
  }
  .bg-dark {
    background-color: black!important;
  }
</style>
