<script setup>
import { onMounted, computed, ref } from 'vue';
// import { useProfileStore } from "@/stores/profileStore";
import { useCountryStore } from "@/stores/countryStore";
// import { useSystemStore } from "@/stores/systemStore";
// import PaginationView from '@/components/PaginationView.vue';

// const profileStore = useProfileStore();
const countryStore = useCountryStore();
const loading = ref(false);
const searchText = ref();
const countryList = computed(() => {
    if (!searchText.value) return countryStore.countries;
    let returnVals = [];
    countryStore.countries.forEach(country => {
        if (country.name.includes(searchText.value)) returnVals.push(country);
    })
    return returnVals;
})
// const countries = ref([]);
// const pageNo = ref(1);
// const totalPages = ref();
// const itemsPerPage = 10;
// const pageNumberReceived = (newPageNo) => {
//     console.log("Rcvd",newPageNo);
//     pageNo.value = newPageNo;
// }
// const system = useSystemStore();
onMounted(async () => {
    loading.value = true;
    await countryStore.FetchAll();
    // const countriesUrl = profileStore.rootApi + "/gues/countries";
    // let response = await fetch(countriesUrl);
    // let data = response.json();
    // if (response.status == 200) countries.value = data;
    loading.value = false;
});

</script>
<template>
    <div class="dashboard-countries">
        <h3>Countries
        </h3>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="table-responsive">
            <input type="search" v-model="searchText" id="" class="form-control form-control-sm" placeholder="Filter...">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th class="text-center">Hague?</th>
                        <th>Code</th>
                        <th>Dial</th>
                        <!-- <th>...</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="country in countryList" :key="country.id">
                        <td>{{ country.name }}</td>
                        <td class="text-center">{{ (country.isHague) ? 'Y' : 'N' }}</td>
                        <td>{{ country.cc2 }}</td>
                        <td>{{ country.callingCode }}</td>
                        <!-- <td title="Edit"><i class="bi bi-pencil"></i></td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style scoped>
i {
    cursor: pointer;
}
</style>