import { defineStore } from 'pinia'
import { useProfileStore } from "@/stores/profileStore";

export const useLoggingStore = defineStore('loggingStore', {
    state: () => ({
        logLines: []
    }),
    actions: {
        async Fetch() {
            const profile = useProfileStore();
            let url = profile.rootApi + "/author/log";
            try {
                let response = await fetch(url,{
                    method: 'get',
                        headers: new Headers({
                            Authorization: "Bearer " + profile.jwt,
                        }),
                });
                if (response.status == 200) this.logLines = await response.json();
                return true;
            } catch (err) {
                return err;
            }
        }
    },
})