<template>
    <div class="container customer">
        <h3>User</h3>
        <form @submit.prevent="saveUser">
        <div v-if="user.id" class="userID">
            <label for="user.id">#</label>
            <input type="text" v-model="user.id" id="user.id" class="form-control" readonly>
        </div>
            <label for="user.email">Email</label>
            <input type="email" v-model="user.email" id="user.email" class="form-control" placeholder="Email address" required>
            <label for="user.firstname">First Name</label>
            <input type="text" v-model="user.firstname" id="user.firstname" class="form-control" placeholder="First Name">
            <label for="user.lastname">Last Name</label>
            <input type="text" v-model="user.lastname" id="user.lastname" class="form-control" placeholder="Last Name">
            <label for="user.mobile">Mobile</label>
            <input type="tel" v-model="user.mobile" id="user.mobile" class="form-control" placeholder="Mobile">
            <label for="user.phone">Telephone</label>
            <input type="tel" v-model="user.phone" id="user.phone" class="form-control" placeholder="Landline">
            <button class="btn btn-sm btn-success mt-2" style="width: 100px;">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>
                    Save
                </span>
            </button>
        </form>
    </div>
</template>
<script setup>
    import { ref, onBeforeMount } from 'vue';
    import { useSystemStore } from '@/stores/systemStore';
    import { useRoute } from 'vue-router';
    import router from '@/router';
    const loading = ref(false);
    const systemStore = useSystemStore();
    const user = ref({});
    const route = useRoute();
    const saveUser = () => {
        loading.value = true;
        systemStore.saveUser(user.value);
        router.push('/users');
    }
    onBeforeMount( () => {
        if (route.params.id) {
            user.value = systemStore.fetchUser(route.params.id);
        }
    })
</script>