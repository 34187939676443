<template>
    <div class="container customer">
        <h3>Customer</h3>
        <form @submit.prevent="saveCustomer">
        <div v-if="customer.id" class="customerID">
            <label for="customer.id">#</label>
            <input type="text" v-model="customer.id" id="customer.id" class="form-control" readonly>
        </div>
            <label for="customer.email">Email</label>
            <input type="email" v-model="customer.email" id="customer.email" class="form-control" placeholder="Email address">
            <label for="customer.name">Name</label>
            <input type="text" v-model="customer.name" id="customer.name" class="form-control" placeholder="Name or Company Name">
            <label for="customer.phone">Telephone</label>
            <input type="tel" v-model="customer.phone" id="customer.phone" class="form-control" placeholder="Landline or Mobile">
            <button class="btn btn-sm btn-success mt-2">Save</button>
        </form>
    </div>
</template>
<script setup>
    import { ref, onBeforeMount } from 'vue';
    import { useSystemStore } from '@/stores/systemStore';
    import { useRoute } from 'vue-router';
    const systemStore = useSystemStore();
    const customer = ref({});
    const route = useRoute();
    const saveCustomer = () => {
        systemStore.saveCustomer(customer);
    }
    onBeforeMount( () => {
        if (route.params.id) {
            customer.value = systemStore.fetchCustomer(route.params.id);
        }
    })
</script>