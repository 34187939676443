<template>
    <div class="dashboard-payments">
        <div class="headerElement d-flex justify-content-between align-items-center">
            <h3 :title="lastChecked">Payments | <button class="btn btn-sm btn-info"
                    @click.prevent="includeCanceled = !includeCanceled">
                    <span v-if="includeCanceled">
                        Live Only
                    </span>
                    <span v-else>
                        Show All
                    </span>
                </button> |
                <router-link class="btn btn-sm btn-outline-dark"
                    to="/payment">&plus;</router-link>
            </h3>
            <span class="lastChecked" v-if="lastChecked && profileStore.profile.isAuthor" style="font-size: .8em;">{{
                    lastChecked
            }}</span>
        </div>
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="table-responsive">
            <form class="d-flex align-items-center" @submit.prevent="fetchPayments">
                <label for="filter.startDate">From</label>
                <input type="date" v-model="filter.startDate" id="filter.startDate"
                    class="form-control form-control-sm mx-1">
                <label for="filter.endDate">To</label>
                <input type="date" v-model="filter.endDate" id="filter.endDate"
                    class="form-control form-control-sm mx-1">
                <button type="submit" class="btn btn-sm btn-primary">Go</button>
            </form>
            <table class="table table-sm">
                <thead>
                    <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col" title="Business Unit">Dept</th>
                        <th scope="col">Date</th>
                        <th scope="col">Ref</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Owner</th>
                        <th scope="col">Status</th>
                        <th colspan="2">...</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="payment in payments" :key="payment.id">
                        <!-- <td><router-link :to="'/payment/'+payment.id">{{ payment.id }}</router-link></td> -->
                        <!-- <td>{{ payment.id }}</td> -->
                        <td>{{ resolveBusinessUnit(payment) }}</td>
                        <td>{{ resolvePaymentDate(payment) }}</td>
                        <td :title="resolveCustomer(payment.customer)">{{ payment.description }}</td>
                        <td>{{ formatPayment(payment.amount) }}</td>
                        <td>{{ resolvePaymentOwner(payment) }}</td>
                        <td>{{ resolveStatus(payment) }}</td>
                        <td><i v-if="payment.status == 'requires_action' || payment.status == 'requires_payment_method'"
                                class="bi bi-repeat" title="Resend" @click="sendPaymentIntent(payment.id)"
                                style="cursor: pointer;"></i></td>
                        <td
                            v-if="payment.status == 'requires_capture' || payment.status == 'succeeded' || payment.status == 'requires_payment_method'">
                            <i v-if="payment.status == 'requires_capture' || payment.status == 'requires_payment_method'"
                                class="bi bi-x-circle" title="Cancel" @click="cancelPaymentIntent(payment.id)"
                                style="cursor: pointer; color: red;"></i>
                            <i v-else-if="resolveStatus(payment) != 'Refunded'" class="bi bi-x-circle-fill"
                                title="Refund" @click="refundPaymentIntent(payment.id)"
                                style="cursor: pointer; color: red;"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total</th>
                        <th></th>
                        <th></th>
                        <th>{{ totalAmount }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import { useSystemStore } from "@/stores/systemStore";
import { usePaymentStore } from "@/stores/paymentStore";
import { useProfileStore } from "@/stores/profileStore";

const lastChecked = ref(false);
const loading = ref(false);
const filter = ref({});
// const totalAmount = ref(0);
const includeCanceled = ref(false);
const system = useSystemStore();
const paymentStore = usePaymentStore();
const profileStore = useProfileStore();
const payments = computed(() => {
    if (includeCanceled.value === true) return system.payments.data;
    let returnVal = [];
    for (let p in system.payments.data) {
        if (system.payments.data[p].status != "canceled") returnVal.push(system.payments.data[p]);
    }
    return returnVal;
})
const totalAmount = computed(() => {
    let returnVal = 0; console.log("Subtotal", returnVal);
    console.log(payments);
    for (let p in payments.value) {
        // console.log(payments[p].amount);
        returnVal += parseFloat(payments.value[p].amount);
        // console.log("Subtotal", returnVal);
    }
    return returnVal.toFixed(2);
})
const formatPayment = (amount) => amount.toFixed(2);

const sendPaymentIntent = (paymentIntentID) => {
    paymentStore.sendPaymentIntent(paymentIntentID);
}

const resolvePaymentDate = (payment) => {
    return payment.date;
}

const resolveStatus = (payment) => {
    return paymentStore.resolveStatus(payment);
}

const cancelPaymentIntent = async (paymentIntentID) => {
    if (confirm("Are you sure you want to cancel the Payment Intent " + paymentIntentID + "?") === true) {
        await paymentStore.cancelPaymentIntent(paymentIntentID);
        fetchPayments();
    }
}

const refundPaymentIntent = async (paymentIntentID) => {
    if (confirm("Are you sure you want to refund the Payment Intent " + paymentIntentID + "?") === true) {
        await paymentStore.refundPaymentIntent(paymentIntentID);
    }
}

const resolveCustomer = (customerID) => {
    let customer = system.fetchCustomer(customerID);
    return (customer != customerID) ? customer.email : customer;
};

const resolveBusinessUnit = (payment) => {
    let businessUnit = system.getBusinessUnit(payment);
    return (businessUnit) ? businessUnit : 'TBA';
};

const resolvePaymentOwner = (payment) => {
    let owner = system.resolvePaymentOwner(payment);
    return (owner) ? owner : '-';
}
// const getPaymentReference = (payment) => {
//     let paymentReference = system.getPaymentReference(payment);
//     return (paymentReference) ? paymentReference : '-';
// };

const fetchPayments = async () => {
    loading.value = true;
    await system.fetchPayments(filter.value);
    let thisDate = new Date();
    let thisHour = thisDate.getHours() < 10 ? "0" + thisDate.getHours() : thisDate.getHours();
    let thisMinute = thisDate.getMinutes() < 10 ? "0" + thisDate.getMinutes() : thisDate.getMinutes();
    let thisSecond = thisDate.getSeconds() < 10 ? "0" + thisDate.getSeconds() : thisDate.getSeconds();
    lastChecked.value = thisHour + ":" + thisMinute + ":" + thisSecond;
    // let subtotal = 0;
    // for (let p in system.payments.data) subtotal += system.payments.data[p].amount;
    // if (subtotal != totalAmount.value) totalAmount.value = subtotal.toFixed(2);
    loading.value = false;
}

// const displayTotal = () => {
//     return (totalAmount.value > 0) ? parseFloat(totalAmount.value).toFixed(2) : "0.00";
// }

onMounted(async () => {
    // if (profileStore.profile.isAuthor) {
    let thisDate = new Date();
    let thisMonth = thisDate.getMonth() < 9 ? "0" + (thisDate.getMonth() + 1) : thisDate.getMonth() + 1;
    let thisDay = thisDate.getDate() < 10 ? "0" + thisDate.getDate() : thisDate.getDate();
    filter.value.startDate = thisDate.getFullYear() + '-' + thisMonth + '-' + thisDay;
    // }
    await system.fetchCustomers();
    fetchPayments();
});

// setInterval(fetchPayments,30000);
</script>