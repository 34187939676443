<template>
    <div class="dashboard-home">
        <h3>Welcome</h3>
        <!-- <BarChart style="height: 200px; width: 250px;" :chartData="{
          labels: [ 'January', 'February', 'March', 'April' ],
          datasets: [ { data: [20, 30, 25, 40] } ]
        }" /> -->
    </div>
</template>
<script setup>
import { onBeforeMount } from 'vue';
import { useSystemStore } from '@/stores/systemStore';
// import BarChart from '@/components/BarChart'

onBeforeMount(() => {
    const systemStore = useSystemStore();
    systemStore.fetchReference();
})
</script>
<style>
.cdn_logo {
    width: 100%;
    display: inline-block;
    padding: 0.5em 0 0 0.5em;
    font-size: 2em;
}

.feather {
    width: 16px;
    height: 16px;
}

/*
   * Sidebar
   */
.sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 48px 0 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    height: calc(100vh - 48px);
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
}

.sidebar .nav-link.active {
    color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
}

/*
   * Navbar
   */

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: .75rem 1rem;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}
</style>