<script setup>
import { useRouter } from "vue-router";
import Alerts from '@/components/user-alerts';
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from "@/stores/alertStore";
import { onBeforeMount } from '@vue/runtime-core';
import DashNavTop from './Navs/dash-nav-top.vue';
import DashNavSide from './Navs/dash-nav-side.vue';

const router = useRouter();
const profile = useProfileStore();
const alertStore = useAlertStore();
onBeforeMount(async () => {
    profile.fetchJwt();
    let result = await profile.fetchProfile();
    if (result !== true) {
        alertStore.add('warning', 'Authentication Failed', 'Sorry - you need to login again');
        router.replace("/login");
    }
})
</script>

<template>
    <div id="page">
        <DashNavTop />
        <div v-if="profile.jwt" class="container-fluid">
            <div class="row">
                <DashNavSide />
                <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-3" v-if="profile.jwt">
                    <router-view />
                </main>
            </div>
        </div>
        <main v-else style="min-height: 75vh;">
            <router-view />
        </main>
        <footer>

        </footer>
        <Alerts />
    </div>
</template>

<style lang="scss">
@import "./node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: .875rem;
}

.nav-link {
    cursor: pointer;
}

footer {
    margin-top: 2rem;
    min-height: 75px;
    background-color: white;
    // color: white;
    // padding: 2rem;
}
</style>