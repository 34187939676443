<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div class="position-sticky pt-3 sidebar-sticky">
            <ul class="nav flex-column">
                <li v-for="navRoute in navRoutes" :key="navRoute.name" class="nav-item">
                    <router-link class="nav-link" :to="navRoute.path">
                        <i class="bi" :class="navRoute.icon"></i>
                        {{ navRoute.name }}
                    </router-link>
                </li>

                <router-link v-if="profile.profile.isSuperAdmin == 1 || profile.profile.isAuthor == 1" class="nav-link"
                    to="/countries">
                    <i class="bi bi-map"></i>
                    Countries
                </router-link>

                <router-link v-if="profile.profile.isSuperAdmin == 1 || profile.profile.isAuthor == 1" class="nav-link"
                    to="/pricing">
                    <i class="bi bi-currency-pound"></i>
                    Pricing
                </router-link>

                <router-link v-if="profile.profile.isSuperAdmin == 1 || profile.profile.isAuthor == 1" class="nav-link"
                    to="/users">
                    <i class="bi bi-person"></i>
                    Users
                </router-link>

                <router-link
                    v-if="profile.profile.isSuperAdmin == 1 || profile.profile.isAuthor == 1 || profile.profile.isFinance == 1"
                    class="nav-link" to="/payouts">
                    <i class="bi bi-wallet"></i>
                    Payouts
                </router-link>

                <router-link v-if="profile.profile.isAuthor == 1" class="nav-link" to="/customers">
                    <i class="bi bi-list-task"></i>
                    Customers
                </router-link>

                <router-link v-if="profile.profile.isAuthor == 1" class="nav-link" to="/logging">
                    <i class="bi bi-list-task"></i>
                    Log
                </router-link>


                <router-link class="nav-link" to="/password">
                    <i class="bi bi-lock"></i>
                    Password
                </router-link>

                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="logout">
                        <i class="bi bi-box-arrow-right"></i>
                        Sign-Out
                    </a>
                </li>
            </ul>

            <!-- <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
          <span>Saved reports</span>
          <a class="link-secondary" href="#" aria-label="Add a new report">
            <span data-feather="plus-circle" class="align-text-bottom"></span>
          </a>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text" class="align-text-bottom"></span>
              Current month
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text" class="align-text-bottom"></span>
              Last quarter
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text" class="align-text-bottom"></span>
              Social engagement
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <span data-feather="file-text" class="align-text-bottom"></span>
              Year-end sale
            </a>
          </li>
        </ul> -->
        </div>
    </nav>
</template>
<style scoped>
a.router-link-exact-active {
    color: red !important;
}

#sidebarMenu {
    padding-top: 96px;
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 0;
    }

}
</style>
<script setup>
import { useProfileStore } from "@/stores/profileStore";
import { useRouter } from 'vue-router';
const profile = useProfileStore();
const router = useRouter();

const navRoutes = [
    { name: 'Dashboard', path: '/dashboard', icon: 'bi-house-door' },
    { name: 'Payments', path: '/payments', icon: 'bi-currency-pound' },
    // {name: 'Orders', path: '/orders', icon: 'bi-clipboard-check'}
]

const logout = async () => {
    await profile.logout();
    router.replace('/');
}
</script>